/* For contact form */

#loading-pane {
    display: none;
    opacity: 0.8;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    filter: alpha(opacity=80);
    -moz-opacity: 0.8;
    z-index: 999998;
    background-color: #ffffff;
}

    #loading-pane img {
        position: absolute;
        top: 40%;
        left: 47%;
        z-index: 999999;
    }